
/** @jsxImportSource @emotion/react */

import React, { CSSProperties, useEffect, useRef, useState } from "react"
import { css } from "@emotion/react"
import { motion, useForceUpdate } from "framer-motion"

import Ripple from "./Ripple"


class RippleTouchEffectState {
    ripples: Ripple[] = []
    activeRipple?: Ripple
}


const RootStyle = css({
    overflow: "hidden",
    position: "absolute",
    inset: 0
})


export type RippleTouchEffectClickHandler = (event: React.MouseEvent<HTMLDivElement>) => void
export type RippleTouchEffectHoverHandler = () => void
export type RippleTouchEffectBlurHandler = () => void


export interface RippleTouchEffectProps {
    isDisabled?: boolean

    rippleColor?: string
    rippleOpacity?: number

    glowColor?: string
    glowOpacity?: number
    glowAnimationDuration?: number

    onClick?: RippleTouchEffectClickHandler
    onHover?: RippleTouchEffectHoverHandler
    onBlur?: RippleTouchEffectBlurHandler

    children?: React.ReactNode
    style?: CSSProperties
}


export default function RippleTouchEffect(props: RippleTouchEffectProps) {
    const rootRef = useRef<HTMLDivElement>(null)

    const [forceUpdate, updateIteration] = useForceUpdate()
    const [state, setRippleState] = useState(new RippleTouchEffectState())
    const [isHovered, setIsHovered] = useState<boolean | undefined>()
    const [mousePosition, setMousePosition] = useState({ x: -1, y: -1 })

    const isDisabled = props.isDisabled

    const rect = rootRef.current?.getBoundingClientRect() ?? { width: 0, height: 0, left: 0, top: 0, right: 0, bottom: 0 }

    const centerOffsetX = Math.abs(rect.width * 0.5 - mousePosition.x)
    const centerOffsetY = Math.abs(rect.height * 0.5 - mousePosition.y)

    const expandedWidth = rect.width + centerOffsetX * 2
    const expandedHeight = rect.height + centerOffsetY * 2

    const glowRadius = Math.sqrt(expandedWidth * expandedWidth + expandedHeight * expandedHeight)

    const glowColor = props.glowColor ?? props.rippleColor ?? "#ffffff"
    const glowOpacity = props.glowOpacity
    const rippleColor = props.rippleColor ?? "#ffffff"
    const rippleOpacity = props.rippleOpacity


    useEffect(() => {
        const cleanupTimer = setTimeout(() => {
            const ripplesCount = state.ripples.length
            state.ripples = state.ripples.filter(ripple => ripple.getStatus() !== "hidden")
            if (ripplesCount !== state.ripples.length) { forceUpdate() }
        }, 1000)

        return () => {
            clearTimeout(cleanupTimer)
        }
    })


    useEffect(() => {
        const disappearTimer = setTimeout(() => {
            const found = state.ripples.find(ripple => ripple.getStatus() === "disappearing")
            if (found) { forceUpdate() }
        }, 200)

        return () => {
            clearTimeout(disappearTimer)
        }
    })


    const disappearActiveRipple = () => {
        if (!state.activeRipple) { return }

        state.activeRipple.setStatus("disappear")
        state.activeRipple = undefined

        forceUpdate()
    }


    const onMouseDown = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (isDisabled) { return }
        if (!rootRef.current) { return }
        if (state.activeRipple) { return }

        const rect = rootRef.current.getBoundingClientRect()
        const x = event.clientX - rect.left
        const y = event.clientY - rect.top

        const targetWidth = event.currentTarget.clientWidth
        const targetHeight = event.currentTarget.clientHeight

        const ripple = new Ripple(rippleColor, x, y, targetWidth, targetHeight)

        state.ripples.push(ripple)
        state.activeRipple = ripple

        forceUpdate()
    }


    const onMouseUp = () => {
        if (!state.activeRipple) { return }

        disappearActiveRipple()
    }


    const onMouseMove = (event: MouseEvent) => {
        if (!rootRef.current) { return }
        if (props.glowOpacity === 0.0) { return }

        const rect = rootRef.current.getBoundingClientRect()

        const localX = event.clientX - rect.left
        const localY = event.clientY - rect.top

        let isInside = true

        if (localX < 0) { isInside = false }
        if (localY < 0) { isInside = false }
        if (localX >= rect.width) { isInside = false }
        if (localY >= rect.height) { isInside = false }

        if (isInside !== isHovered) {
            setIsHovered(isInside)

            if (props.onHover && isInside) { props.onHover() }
            if (props.onBlur && !isInside) { props.onBlur() }
        }

        if (isInside) {
            setMousePosition({ x: localX, y: localY })
        }
    }


    const onClick = (event: React.MouseEvent<HTMLDivElement>) => {
        if (isDisabled) { return }
        if (!props.onClick) { return }

        props.onClick(event)
    }


    useEffect(() => {
        window.addEventListener("mousemove", onMouseMove)
        window.addEventListener("mouseup", () => onMouseUp())
        return () => {
            window.removeEventListener("mousemove", onMouseMove)
            window.removeEventListener("mouseup", onMouseUp as any)
        }
    })


    return (
        <div
        style={{ position: "relative", ...props.style }}
        ref={rootRef}
        onMouseDown={onMouseDown}
        onMouseUp={onMouseUp}
        onClick={onClick}>
            <div css={RootStyle}>
                { props.onBlur && props.onHover ?
                    <div
                    css={RootStyle}
                    style={{ opacity: isDisabled ? 0 : (isHovered ? glowOpacity ?? 0.15 : 0) }}>
                        <div style={{
                            position: "absolute",
                            left: mousePosition.x,
                            top: mousePosition.y,
                            width: 1,
                            height: 1,
                            transform: `scale(${glowRadius}, ${glowRadius})`,
                            background: `radial-gradient(ellipse at center, ${glowColor} 0%, transparent 70%)`,
                            pointerEvents: "none"
                        }} />
                    </div>
                :
                    <motion.div
                    css={RootStyle}
                    initial={{ opacity: 0 }}
                    whileHover={{ opacity: isDisabled ? 0 : (glowOpacity ?? 0.15) }}
                    transition={{ duration: props.glowAnimationDuration ?? 0.2, ease: "linear" }}>
                        <div style={{
                            position: "absolute",
                            left: mousePosition.x,
                            top: mousePosition.y,
                            width: 1,
                            height: 1,
                            transform: `scale(${glowRadius}, ${glowRadius})`,
                            background: `radial-gradient(ellipse at center, ${glowColor} 0%, transparent 70%)`,
                            pointerEvents: "none"
                        }} />
                    </motion.div>
                }

                <div css={RootStyle} style={{ opacity: rippleOpacity ?? 0.2, pointerEvents: "none" }}>
                    { state.ripples.map(ripple => ripple.render()) }
                </div>
            </div>

            {props.children}
        </div>
    )
}
