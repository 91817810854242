
import { Ingredient } from "../../../providers/recipes"
import { Product } from "../../../providers/products"


export interface IngredientViewProps {
    ingredient: Ingredient
}


interface InternalIngredientViewProps {
    product: Product
}


function InternalIngredientView(props: InternalIngredientViewProps) {
    const nameRequest = props.product.name

    return (
        <div>
            <span style={{ margin: "0 10px 0 0", opacity: 0.5 }}>•</span>
            <span>{nameRequest.data}</span>
        </div>
    )
}


export default function IngredientView(props: IngredientViewProps) {
    const productRequest = props.ingredient.product

    return (
        <div>{ productRequest.data ? <InternalIngredientView product={productRequest.data} /> : <div /> }</div>
    )
}
