
import "reflect-metadata"

import React from "react"
import ReactDOM from "react-dom"
import App from "./App"
import { MotionConfig } from "framer-motion"
import { QueryClient, QueryClientProvider } from "react-query"

import * as serviceWorkerRegistration from "./serviceWorkerRegistration"
import reportWebVitals from "./reportWebVitals"


const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            refetchOnMount: false
        }
    }
})


ReactDOM.render(
    <React.StrictMode>
        <MotionConfig transition={{ duration: 0.2,  easing: "linear" }}>
            <QueryClientProvider client={queryClient}>
                <App />
            </QueryClientProvider>
        </MotionConfig>
    </React.StrictMode>,
    document.getElementById("root")
)


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister()


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
