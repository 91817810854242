
import { IonCol, IonContent, IonGrid, IonHeader, IonInfiniteScroll, IonInfiniteScrollContent, IonPage, IonRow, IonTitle, IonToolbar } from "@ionic/react"
import { useEffect, useState } from "react"
import { RouteComponentProps } from "react-router-dom"

import { Recipe, useRecipes } from "../../providers/recipes"
import { RecipeListItem } from "./components/RecipesListItem"


export interface RecipesPageProps extends RouteComponentProps {
}


export default function RecipesPage(props: RecipesPageProps) {
    const [recipes, setRecipes] = useState<Recipe[]>([])
    const [isInfiniteDisabled, setInfiniteDisabled] = useState(false)

    const recipesRequest = useRecipes()

    const pushRecipes = () => {
        const allRecipes = Object.values(recipesRequest.data ?? {})

        const limit = Math.min(recipes.length + 20, allRecipes.length - 1)
        const newRecipes = allRecipes.slice(0, limit)

        setRecipes(newRecipes)
    }

    const loadData = (event: any) => {
        const allRecipes = Object.values(recipesRequest.data ?? {})

        pushRecipes()

        event.target.complete()
        
        if (recipes.length === allRecipes.length) {
            setInfiniteDisabled(true)
        }
    }

    useEffect(() => {
        pushRecipes()
    }, [recipesRequest.isLoading])

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>Все рецепты</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen>
                <IonGrid>
                    <IonRow>
                    {recipes.map((recipe, index) => {
                        return (
                            <IonCol size="4" sizeXl="2" key={index}>
                                <RecipeListItem recipe={recipe} history={props.history} location={props.location} match={props.match} />
                            </IonCol>
                        )
                    })}
                    </IonRow>
                </IonGrid>

                <IonInfiniteScroll onIonInfinite={loadData} threshold="100px" disabled={isInfiniteDisabled} >
                    <IonInfiniteScrollContent loadingSpinner="bubbles" loadingText="Loading more data..." />
                </IonInfiniteScroll>
            </IonContent>
        </IonPage>
    )
}
