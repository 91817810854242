
import { useText } from "./texts"


export class BaseModel {
    get typeName(): string { return "unknown" }


    id!: string
    

    // eslint-disable-next-line react-hooks/rules-of-hooks
    resolveString(key: string) { return useText(this.id, key, this.typeName) }
}
