
/** @jsxImportSource @emotion/react */

import { v4 as uuidv4 } from "uuid"
import { motion } from "framer-motion"


type RippleStatus = "appear" | "disappear" | "disappearing" | "hidden"


export default class Ripple {
    id: string = uuidv4()


    private createDate: Date = new Date()
    private status: RippleStatus = "appear"
    private radius: number


    constructor(private color: string, private x: number, private y: number, targetWidth: number, targetHeight: number) {
        const centerOffsetX = Math.abs(targetWidth * 0.5 - this.x)
        const centerOffsetY = Math.abs(targetHeight * 0.5 - this.y)

        const expandedWidth = targetWidth + centerOffsetX * 2
        const expandedHeight = targetHeight + centerOffsetY * 2

        this.radius = Math.sqrt(expandedWidth * expandedWidth + expandedHeight * expandedHeight)
    }


    getStatus(): RippleStatus {
        return this.status
    }


    setStatus(state: RippleStatus) {
        this.status = state

        if (state === "disappear") {
            const aliveDelta = new Date().getTime() - this.createDate.getTime()

            if (aliveDelta < 100) {
                setTimeout(() => { this.setStatus("disappearing") }, 100)
            } else {
                this.setStatus("disappearing")
            }
        }

        if (state === "disappearing") {
            setTimeout(() => { this.status = "hidden" }, 500)
        }
    }


    render() {
        const isVisible = this.status === "appear" || this.status === "disappear"

        return (
            <motion.div
            key={this.id}
            style={{
                position: "absolute",
                left: this.x,
                top: this.y,
                width: 1,
                height: 1,
                background: `radial-gradient(ellipse at center, ${this.color} 0%, transparent 70%)`
            }}

            initial={{
                opacity: 0,
                transform: `scale(0, 0)`
            }}

            animate={{
                opacity: isVisible ? 1 : 0,
                transform: isVisible ? `scale(${this.radius}, ${this.radius})` : `scale(${this.radius * 4}, ${this.radius * 4})`
            }}

            transition={{
                duration: isVisible ? 0.3 : 0.5,
                ease: isVisible ? "easeOut" : "linear"
            }} />
        )
    }
}
