
import { useQuery } from "react-query"
import { plainToClass } from "class-transformer"

import Config from "../config"


export class Text {
    id!: string
    text!: string
}


export function useTexts() {
    return useQuery("texts", async () => {
        let texts: { [id: string]: Text } = {} 

        const response = await fetch(`${Config.cdnBaseURL}/textsRu.json`)
        const json = await response.json()
        const flatTexts = plainToClass(Text, json as Object[])

        for (const text of flatTexts) {
            texts[text.id] = text
        }

        return texts
    })
}


export function useText(id: string, key: string, type: string) {
    const textsResult = useTexts()

    return useQuery(["text", id, key, type], async () => {
        const texts = textsResult.data
        const fullId = `__${id}.${key}@${type}`

        return texts && texts[fullId].text
    }, { enabled: textsResult.data !== undefined })
}
