
import { IonImg, IonLabel } from "@ionic/react"
import { RouteComponentProps } from "react-router"

import RippleTouchEffect from "../../../components/RippleTouchEffect/RippleTouchEffect"
import { Recipe } from "../../../providers/recipes"


export interface RecipeListItemProps extends RouteComponentProps {
    recipe: Recipe
} 


export function RecipeListItem(props: RecipeListItemProps) {
    const shortNameRequest = props.recipe.shortName

    const onClick = () => {
        props.history.push(`/recipes/${props.recipe.id}`)
    }

    return (
        <div style={{ position: "relative", aspectRatio: "1" }}>
            <IonImg src={props.recipe.thumbURL} style={{ position: "absolute", inset: 0, objectFit: "cover" }} />
            <div style={{ position: "absolute", inset: 0, background: "linear-gradient(180deg, rgba(0,0,0,0) 50%, rgba(0,0,0,0.75) 100%)" }} />

            <RippleTouchEffect style={{ position: "absolute", inset: 0 }} glowOpacity={0.3} rippleOpacity={0.5} onClick={onClick}>
                <IonLabel style={{ position: "absolute", left: 10, right: 10, bottom: 10, pointerEvents: "none", fontWeight: "bold" }}>{shortNameRequest.data}</IonLabel>
            </RippleTouchEffect>
        </div>
    )
}
