
import { Step, StepsSection } from "../../../providers/recipes"


export interface StepsSectionViewProps {
    stepsSection: StepsSection
}


interface StepViewProps {
    index: number
    step: Step
}


function StepView(props: StepViewProps) {
    const textRequest = props.step.text

    return (
        <div style={{ display: "flex", gap: 8 }}>
            <div><div style={{ backgroundColor: "rgba(255, 255, 255, 0.1)", borderRadius: 999, height: 20, width: 20, fontSize: 12, display: "flex", alignItems: "center", justifyContent: "center" }}>{props.index + 1}</div></div>
            <div style={{ fontSize: 15, paddingTop: 1 }}>{textRequest.data}</div>
        </div>
    )
}


export default function StepsSectionView(props: StepsSectionViewProps) {
    const nameRequest = props.stepsSection.name

    return (
        <div style={{ display: "flex", flexDirection: "column", gap: 8 }}>
            { nameRequest.data ? <div style={{ fontWeight: "bold" }}>{nameRequest.data}</div> : undefined }
            <div style={{ display: "flex", flexDirection: "column", gap: 8 }}>
            { props.stepsSection.steps.map((step, index) => <StepView step={step} index={index} />) }
            </div>
        </div>
    )
}
