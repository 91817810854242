
import { useQuery } from "react-query"
import { plainToClass, Type } from "class-transformer"

import Config from "../config"
import { BaseModel } from "./baseModel"
import { useProduct } from "./products"


export class Step extends BaseModel {
    get typeName(): string { return "recipeSteps" }


    get text() { return this.resolveString("text") }
}


export class StepsSection extends BaseModel {
    get typeName(): string { return "recipeStepsSections" }


    @Type(() => Step) steps!: Step[]


    get name() { return this.resolveString("name") }
}


export class Ingredient extends BaseModel {
    productId!: string


    // eslint-disable-next-line react-hooks/rules-of-hooks
    get product() { return useProduct(this.productId) }
}


export class Recipe extends BaseModel {
    get typeName(): string { return "recipes" }


    @Type(() => Ingredient) ingredients!: Ingredient[]
    @Type(() => StepsSection) stepsSections!: StepsSection[]


    get name() { return this.resolveString("name") }
    get shortName() { return this.resolveString("shortName") }
    get about() { return this.resolveString("about") }


    get imageURL() { return `${Config.cdnBaseURL}/recipes/${this.id}/main.jpeg` }
    get thumbURL() { return `${Config.cdnBaseURL}/recipes/${this.id}/thumb.jpeg` }
}


export function useRecipes() {
    return useQuery("recipes", async () => {
        let recipes: { [id: string]: Recipe } = {} 

        const response = await fetch(`${Config.cdnBaseURL}/recipes.json`)
        const json = await response.json()
        const flatRecipes = plainToClass(Recipe, json as Object[])

        for (const recipe of flatRecipes) {
            recipes[recipe.id] = recipe
        }

        return recipes
    })
}


export function useRecipe(id: string) {
    const recipesResult = useRecipes()

    return useQuery(["recipe", id], async () => {
        const recipes = recipesResult.data

        return recipes && recipes[id]
    }, { enabled: recipesResult.data !== undefined })
}
