
import { RouteComponentProps } from "react-router"
import { IonBackButton, IonButtons, IonContent, IonHeader, IonLabel, IonPage, IonTitle, IonToolbar } from "@ionic/react"

import { Recipe, useRecipe } from "../../providers/recipes"
import { useParallaxHeader } from "../../components/ParallaxHeader"
import IngredientView from "./components/IngredientView"
import StepsSectionView from "./components/StepsSectionView"
import RelatedRecipes from "./components/RelatedRecipesView"


export interface RecipePageProps extends RouteComponentProps<RecipePageParams> {
}


interface RecipePageParams {
    id: string
}


interface InternalRecipePageProps extends RouteComponentProps {
    recipe: Recipe
}


function InternalRecipePage(props: InternalRecipePageProps) {
    const nameRequest = props.recipe.name
    const parallax = useParallaxHeader({
        image: props.recipe.imageURL,
        titleColor: "white"
    })

    return (
        <IonPage>
            <IonHeader translucent ref={parallax.ref}>
                <IonToolbar mode="ios">
                    <IonButtons slot="start">
                        <IonBackButton defaultHref="/recipes" />
                    </IonButtons>
                    <IonTitle>{nameRequest.data}</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <div style={{ padding: "0 20px 20px 20px" }}>
                    <IonLabel style={{ opacity: 0.35 }}>ИНГРЕДИЕНТЫ</IonLabel>
                    <div style={{ display: "flex", gap: 8, flexDirection: "column", paddingTop: 20 }}>{ props.recipe.ingredients.map(ingredient => <IngredientView key={ingredient.id} ingredient={ingredient} />) }</div>
                </div>
                <div style={{ height: 8, backgroundColor: "rgba(255,255,255,0.05" }} />
                <div style={{ padding: "20px 20px 20px 20px" }}>
                    <IonLabel style={{ opacity: 0.35 }}>ШАГИ</IonLabel>
                    <div style={{ display: "flex", gap: 32, flexDirection: "column", paddingTop: 20 }}>{ props.recipe.stepsSections.map(stepsSection => <StepsSectionView key={stepsSection.id} stepsSection={stepsSection} />) }</div>
                </div>
                {/* <div style={{ height: 8, backgroundColor: "rgba(255,255,255,0.05" }} />
                <div style={{ padding: "20px 20px 20px 20px" }}>
                    <IonLabel style={{ opacity: 0.35 }}>ОСТАЛЬНЫЕ РЕЦЕПТЫ</IonLabel>
                    <div style={{ display: "flex", gap: 32, flexDirection: "column", paddingTop: 20 }}><RelatedRecipes history={props.history} location={props.location} match={props.match} /></div>
                </div> */}
            </IonContent>
        </IonPage>
    )
}


export default function RecipePage(props: RecipePageProps) {
    const recipe = useRecipe(props.match.params.id)

    return (
        <div>{ recipe.data ? <InternalRecipePage recipe={recipe.data!} history={props.history} location={props.location} match={props.match} /> : <div /> }</div>
    )
}
