
import { Redirect, Route } from "react-router-dom"
import { IonApp, IonRouterOutlet, setupIonicReact } from "@ionic/react"
import { IonReactRouter } from "@ionic/react-router"

import RecipesPage from "./pages/recipes/RecipesPage"
import RecipePage from "./pages/recipes/RecipePage"

import { useTexts } from "./providers/texts"
import { useRecipes } from "./providers/recipes"
import { useProducts } from "./providers/products"


/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css"


/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css"
import "@ionic/react/css/structure.css"
import "@ionic/react/css/typography.css"


/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css"
import "@ionic/react/css/float-elements.css"
import "@ionic/react/css/text-alignment.css"
import "@ionic/react/css/text-transformation.css"
import "@ionic/react/css/flex-utils.css"
import "@ionic/react/css/display.css"


/* Theme variables */
import "./theme/variables.css"


setupIonicReact()


export default function App() {
    const textsRequest = useTexts()
    const recipesRequest = useRecipes()
    const productsRequest = useProducts()

    return (
        <IonApp>
            <IonReactRouter>
                <IonRouterOutlet>
                    <Route exact path="/"><Redirect to="/recipes" /></Route>
                    <Route exact path="/recipes" component={RecipesPage} />
                    <Route exact path="/recipes/:id" component={RecipePage} />
                </IonRouterOutlet>
            </IonReactRouter>
        </IonApp>
    )
}
