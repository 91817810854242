
import { useQuery } from "react-query"
import { plainToClass } from "class-transformer"

import Config from "../config"
import { BaseModel } from "./baseModel"


export class Product extends BaseModel {
    get typeName(): string { return "products" }


    get name() { return this.resolveString("name") }
}


export function useProducts() {
    return useQuery("products", async () => {
        let products: { [id: string]: Product } = {} 

        const response = await fetch(`${Config.cdnBaseURL}/products.json`)
        const json = await response.json()
        const flatProducts = plainToClass(Product, json as Object[])

        for (const product of flatProducts) {
            products[product.id] = product
        }

        return products
    })
}


export function useProduct(id: string) {
    const productsResult = useProducts()

    return useQuery(["product", id], async () => {
        const products = productsResult.data

        return products && products[id]
    }, { enabled: productsResult.data !== undefined })
}
